<template>
    <section>
        <b-modal :active.sync="siteData.customerLoginModalActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <form @submit.prevent="login">
                <div class="modal-card" style="width:auto;">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-centered has-text-weight-bold">Customer Hub Login</p>
                    </header>
                    <section class="modal-card-body">
                        <p class="notification is-danger" v-show="error">{{ error }}</p>
                        <b-input type="text" v-model="username" placeholder="Username" required/>
                        <br>
                        <b-input type="password" v-model="password" placeholder="Password" required/>
                        <p class="notification is-warning" v-show="error2">{{ error2 }}</p>
                    </section>
                    <section class="modal-card-body text-center central-area">
                        <button class="button is-small is-text" @click="goToForgotPassword">Forgot your password?</button>
                    </section>
                    <section class="modal-card-body text-center central-area">
                        <button class="button is-small is-text" @click="goToCreateAccount">No login? Create an Account</button>
                    </section>
                    <footer class="modal-card-foot level">
                        <button class="button level-item close-button" type="button" @click="siteData.customerLoginModalActive = false">Close</button>
                        <button class="button is-primary level-item login-button">Login</button>
                        
                    </footer>
                </div>
            </form>
        </b-modal>
    </section>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "CustomerHubLoginModal",
        data() {
            return {
                username: '',
                password: '',
                error: '',
                error2: '',
                incorrectAttempts: 0,
                result: ''
            }
        },
        props: ['siteData'],
        methods: {
            ...mapActions({
                setCustomer: 'setCustomer',
            }),

            // Login to the account
            login() {
                this.$http.post('customer/check_login', {
                    'username': this.username,
                    'password': this.password
                })
                // Query login
                .then(res => {
                    this.result = res.data;
                    // Success
                    if(this.result === 'true') {
                        return this.$http.post('customer/get_account', {
                            'username': this.username,
                        })
                    // Incorrect
                    } else {
                        this.password = '';
                        this.error = 'Invalid username or password';
                        if(this.incorrectAttempts > 1) {
                            this.error2 = 'If you\'ve forgot your password you can reset it by clicking HERE.';
                        }
                        this.incorrectAttempts++;
                    }
                })
                // Store user data
                .then(res => {
                    if(res.data !== 'false') {
                        this.setCustomer(JSON.parse(res.data));
                        this.siteData.customerLoginModalActive = false;
                        this.$router.push({name: 'customerHome'});
                    } else {
                        this.error = 'System error';
                    }
                    this.username = '';
                    this.password = '';
                })
                .catch(err => console.log(err));
            },
            
            // Go to the create account page
            goToCreateAccount() {
                this.$router.push({name: 'createAccount'});
                this.siteData.customerLoginModalActive = false
            },

            // Go to the forgot password page
            goToForgotPassword() {
                this.$router.push({name: 'forgotPassword'});
                this.siteData.customerLoginModalActive = false
            }
        },
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
    }
</script>

<style lang="scss" scoped>
    .central-area {
        padding: 0 0 15px 0;
    }
    .is-warning {
        margin-top: 15px;
    }
    .login-button {
        width: 150px;
    }
    .close-button {
        width: 150px;
    }
</style>