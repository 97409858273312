<template>
    <div>
        <header-section :siteData="siteData"/>
        <div class="container main-container">
            <br>
            <router-view/>
        </div>
        <transition appear enter-active-class="animated fadeIn slower" leave-active-class="animated fadeOut slower" mode="out-in">
            <footer-section :siteData="siteData"/>
        </transition>
    </div>
</template>

<script>
    import Header from "./components/Header";
    import Footer from "./components/Footer";
    import { mapActions } from "vuex";

    export default {
        name: 'app',
        data() {
            return {
                siteData: {
                    retrieveModalActive: false,
                    customerLoginModalActive: false
                }
            }
        },
        methods: {
            ...mapActions({
                setCallCentre: 'setCallCentre',
                setAffiliation: 'setAffiliation'
            })
        },
        components: {
            headerSection: Header,
            footerSection: Footer
        },
        mounted() {
            this.setCallCentre(null);
            this.setAffiliation(null);
        }
    }
</script>

<style lang="scss">
    @import "styles/_bulma";

    .main-container {
        margin-top: 0px;
    }
</style>
