<template>
    <div>

        <!-- Search box -->
        <p class="control has-icons-left">
            <input class="input" type="text" v-model="search" placeholder="What are you looking for?">
            <span class="icon is-small is-left">
              <i class="fas fa-search"/>
            </span>
        </p>

        <!-- If there is a search -->
        <div v-if="search.length > 1" id="results-box" class="box">

            <!-- If there are results found -->
            <div v-if="results.length > 0">
                <div class="columns title-columns">
                    <div class="column">Results:</div>
                    <div class="column">
                        <button class="delete close" @click="search = ''">X</button>
                    </div>
                </div>

                <!-- Go through each result -->
                <div class="results-container">
                    <div class="columns" v-for="r in results" :key="r.id">

                        <!-- Direct URL -->
                        <a :href="r.url" v-if="r.url !== ''" target="_blank">
                            <button class="button is-info result-btn">{{ r.title }}</button>
                        </a>

                        <!-- Router -->
                        <span @click="search = ''" v-else-if="r.route !== ''">
                            <router-link class="button is-info result-btn" :to="routeLink(r)">
                                {{ r.title }}
                            </router-link>
                        </span>

                    </div>
                </div>
            </div>

            <!-- Nothing found -->
            <div v-else>
                No results found.
                You might be able to find your answer in our <span @click="search = ''"><router-link :to="{name:'faqs'}">FAQs</router-link></span>
            </div>
        </div>

    </div>
</template>

<script>
    const data = require('../../data/global-search');

    export default {
        data() {
            return {
                search: '',
                results: null,
                pageResults: null
            }
        },
        watch: {
            search() {
                this.results = data;
                this.results = this.results.filter(m => m.search.includes(this.search.toLowerCase()));
            }
        },
        methods: {
            routeLink(r) {
                if(r.routeParams !== "") {
                    return {name: r.route, query: r.routeParams};
                } else {
                    return {name: r.route};
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #results-box {
        position: absolute;
        min-width: 300px;
    }
    .result-btn {
        margin: 5px;
    }
    .result-btn:hover {
        background-color: $c2g_orange !important;
    }
    .close {
        float: right;
    }
    .results-container {
        border: 1px solid #ababab;
        padding: 20px;
        border-radius: 10px;
    }
    .title-columns {
        margin-bottom: 0 !important;
    }
</style>