import { getters } from './policy/getters';
import { mutations } from './policy/mutations';
import { actions } from './policy/actions';

const state = {
    policy: null,
    reqPolicy: null,
    upsellPolicy: null,
    callCentre: null,
    customer: null,
    affiliation: null
};

export default {
    state,
    mutations,
    actions,
    getters
}