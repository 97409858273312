export const mutations = {
    // Set the policy object
    setPolicy: ( state, payload ) => {
        state.policy = payload;
    },
    // Set the current policy object
    setReqPolicy: ( state, payload ) => {
        state.reqPolicy = payload;
    },
    // Set the current up-sell policy object
    setUpsellPolicy: ( state, payload ) => {
        state.upsellPolicy = payload;
    },
    // Set the call centre value
    setAffiliation: ( state, payload ) => {
        state.affiliation = payload;
    },
    // Set the call centre value
    setCallCentre: ( state, payload ) => {
        state.callCentre = payload;
    },
    // Set the customer value
    setCustomer: ( state, payload ) => {
        state.customer = payload;
    },
    // Reset error message
    resetError: ( state ) => {
        if(state.policy) {
            state.policy = null;
            state.reqPolicy = null;
        }
    },
};