import Vue from 'vue';
import Vuex from 'vuex';
import policy from './modules/policy';
import blog from './modules/blog';
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        policy,
        blog
    },
    plugins: [createPersistedState()]
});