import { getters } from './blog/getters';
import { mutations } from './blog/mutations';
import { actions } from './blog/actions';

const state = {
    blogs: null
};

export default {
    state,
    mutations,
    actions,
    getters
}