export const helper = {

    // Capitalise fist letter of string
    stringCapFirst(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    },

    // Add strong html tags to parts of the string that match the search
    boldenStringMatch(stringValue, search) {
        stringValue = stringValue.replace(search, `<strong>${search}</strong>`);
        let capFirst = this.stringCapFirst(search);
        stringValue = stringValue.replace(capFirst, `<strong>${capFirst}</strong>`);
        return stringValue;
    },

    thousandSeparator(v) {
        return String(v).replace(/(.)(?=(\d{3})+$)/g,'$1,');
    }

};