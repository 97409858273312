import axios from 'axios';

// C2G
export const baseURL = `https://www.covered2go.co.uk/api/`;
// export const baseURL = `https://www.test.covered2go.co.uk/api/`;
// export const baseURL = `localhost:8100/`;

// CNT
// baseURL: `http://localhost:8099/`
export const contentBaseURL = `https://www.covered2go.co.uk/content-block/`;
// export const contentBaseURL = `https://www.test.covered2go.co.uk/content-block/`;


export const HTTP = axios.create({
    auth: {
        username: 'c2g',
        password: '=D/f/neK(dX!5f2K7buN'
    },
    // auth: {
    //     username: 'rush',
    //     password: 'beckwith'
    // },
    baseURL: baseURL,
    contentBaseURL: contentBaseURL
});

export const HTTPContent = axios.create({
    baseURL: contentBaseURL
});